<template>
    <v-app
        :class="[{
            'layout-mobile': isMobile,
            'layout-desktop': !isMobile
        }, userAgentCssClass]"
    >
        <!-- PRIMARY DRAWER  -->
        <MainSideDrawer
            :nav-items="!lo_isError(menusObj) ? menusObj?.menuMain : []"
        ></MainSideDrawer>

        <!-- MAIN HEADER BAR -->
        <SuperAppBar
            v-if="!lo_isError(menusObj)"
            :nav-items="menusObj?.menuMain"
            :header-config="headerConfig"
        ></SuperAppBar>

        <!-- MAIN CONTENT -->
        <v-main>
            <!-- Membership / Org Bar -->
            <SubAppBar
                v-if="currentMembership"
                :membership-obj="currentMembership"
            ></SubAppBar>

            <!-- Start Content -->
            <slot />
        </v-main>

        <!-- FOOTER -->
        <v-footer
            class="section-border-top"
            :color="footerConfig?.backgroundColor ? footerConfig.backgroundColor : 'primary'"
        >
            <template v-if="!lo_isError(menusObj)">
                <MainFooter
                    :nav-items="menusObj?.menuMain"
                    :nav-items-alt="staticNavItemsList.alt"
                ></MainFooter>
            </template>
        </v-footer>

        <!-- Site Version Check -->
        <client-only>
            <SiteVersionCheck></SiteVersionCheck>
        </client-only>

        <!-- Global Alert -->
        <GlobalAlertNotification></GlobalAlertNotification>

        <!-- Global Environment Tag -->
        <GlobalEnvTag></GlobalEnvTag>

        <DebugContent>
            <template #default='{ bgCssClass }'>
                <div
                    class="debug-position-helper"
                    :class="bgCssClass"
                >
                    <v-chip color="grey white--text" label>
                        <span class="font-weight-bold">{{ useDisplayObj.name.value.toUpperCase() }}</span>
                        <span class="ps-2">{{ `${useDisplayObj.width.value} &times; ${useDisplayObj.height.value}` }}</span>
                    </v-chip>
                </div>
            </template>
        </DebugContent>
    </v-app>
</template>

<script lang="ts" setup>
    import { isError as lo_isError } from 'es-toolkit';
    import { isUndefined as lo_isUndefined } from 'es-toolkit';
    import {
        CreateGlobalAlertKey,
        createGlobalAlert,
        useCarts,
        useMenus,
        useUserLite,
        useIsMobile,
        useCustomThemes,
        useShareASale,
        UserAgentCssClass,
        useGlobalPageBanner,
        isOpenDrawerMenu,
        toggleDocScroller
    } from '~/composables';
    import type {
        CreateGlobalAlert,
        GetMenusResponse,
        DrawerMenu
    } from '~/types';
    import { staticNavItemsList } from '~/models/';
    import { useDisplay } from 'vuetify';

    const MainFooter = defineAsyncComponent(() => import('~/components/footer/MainFooter/MainFooter.vue'));
    const SuperAppBar = defineAsyncComponent(() => import('~/components/header/SuperAppBar/SuperAppBar.vue'));
    const MainSideDrawer = defineAsyncComponent(() => import('~/components/header/menus/MainSideDrawer/MainSideDrawer.vue'));
    const SubAppBar = defineAsyncComponent(() => import('~/components/header/SubAppBar/SubAppBar.vue'));
    const GlobalAlertNotification = defineAsyncComponent(() => import('~/components/global/GlobalAlertNotification/GlobalAlertNotification.vue'));
    const GlobalEnvTag = defineAsyncComponent(() => import('~/components/global/GlobalEnvTag/GlobalEnvTag.vue'));
    const SiteVersionCheck = defineAsyncComponent(() => import('~/components/global/SiteVersionCheck/SiteVersionCheck.vue'));
    const DebugContent = defineAsyncComponent(() => import('~/components/DebugContent/DebugContent.vue'));

    const useDisplayObj = useDisplay();
    const userAgentCssClass = UserAgentCssClass();
    const {
        headerConfig,
        footerConfig,
        initLayout
    } = useCustomThemes();
    const {
        currentMembership,
        isOrgMemberAllowed,
        isLoggedIn
    } = useUserLite();
    const {
        getCart
    } = useCarts();
    const {
        getMenus,
        resetMenuState
    } = useMenus({ siteContext: 'menus' });
    const {
        isMobile
    } = useIsMobile();
    const {
        init: initShareASale
    } = useShareASale();
    const {
        bannerState,
        bannerType,
        toggleBanner
    } = useGlobalPageBanner();

    // Global Alert
    provide<CreateGlobalAlert>(CreateGlobalAlertKey, createGlobalAlert);

    // Theme Init
    initLayout();

    // Global Menus
    const { data: menusObj, refresh: refreshMenuObj } = useLazyAsyncData<GetMenusResponse | Error>(
        'keyGetMenus',
        () => getMenus()
    );

    // Get cart only on client side
    if (import.meta.client) {
        getCart();
    }

    // Don't scroll body when a drawer is open
    const unWatchDrawers = watch(
        isOpenDrawerMenu,
        toggleDocScroller
    );

    // Reload cart/menu on login/logout
    const unWatchLoggedIn = watch(
        isLoggedIn,
        (newVal: boolean, oldVal: boolean | undefined) => {
            setBannerState(newVal);

            if (!lo_isUndefined(oldVal) && newVal !== oldVal) {
                getCart();
                refreshMenuObj();
            }
        }, {
            immediate: true
        }
    );

    function setBannerState(loggedIn: boolean): void {
        let state = bannerState.value;

        if (loggedIn) {
            if (currentMembership.value?.entity?.isOnCreditHold) {
                bannerType.value = 'mbr-credit-hold-alert';
                state = true;
            } else {
                const permission = isOrgMemberAllowed('flag', 'free_shipping_eligible');
                state = lo_isUndefined(permission) ? bannerState.value : permission;
            }
        }

        toggleBanner(state);
    }

    onMounted(() => {
        setTimeout(() => {
            initShareASale();
        }, 100);
    });

    onBeforeUnmount(() => {
        unWatchDrawers();
        unWatchLoggedIn();
        resetMenuState();
        toggleDocScroller(false);
    });
</script>

<style lang="scss">
.main-menu-top {
    position: relative;

    &-close {
        position: absolute;
        top: 0.6rem;
        right: 1rem;
    }
}

.v-application {
    padding-bottom: var(--app-bottom-padding);
}
</style>
